/* Below css for table resizable header */
/* .tr .bg-yellow {
  background-color: '#ffffb8'!important;
} */
.ant-table-thead > tr > th.bg-yellow {
  background: #ffffb8 !important;
}
.ant-table-thead > tr > th.bg-grey {
  background: #bfbfbf !important;
  /* color: #fff !important; */
}
.createTrip .ant-table-thead > tr > .ant-table-selection-column {
  background: #bfbfbf !important;
  /* color: #fff !important; */
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}