/* Below css for table resizable header */
.ant-table-thead > tr > th.bg-blue {
  background: #e4eefa !important;
}
.ant-table-tfoot > tr > th.bg-grey {
  background: #d9d9d9 !important;
}
.ant-table-thead > tr > th.width-50 {
  width: 50% !important;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table,
.ant-table-tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed
  > .ant-table-wrapper:only-child
  .ant-table {
  margin: -4px -4px -3px 45px !important;
}
.ant-table-thead > tr > th.color-head-1 {
  background: #ffa500 !important;
}
.ant-table-thead > tr > th.color-head-2 {
  background: #ffcb6c !important;
}
.ant-table-thead > tr > th.color-head-3 {
  background: #ffebc7 !important;
}
.ant-table-thead > tr > th.color-head-4 {
  background: #233880 !important;
  color: #fff;
}
.ant-table-thead > tr > th.color-head-5 {
  background: #5b70b7 !important;
  color: #fff;
}
.tr > th.color-head-5 > :hover {
  background: #ffcb6c !important;
  color: #fff;
}
.ant-table-thead > tr > th.color-head-6 {
  background: #a6cbf5 !important;
}
.ant-table-thead > tr > th.color-head-red {
  background: #ffa39e !important;
}
.createTrip .ant-table-thead > tr > .ant-table-selection-column {
  background: #bfbfbf !important;
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.width50 {
  visibility: visible;
}

.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-left: -14px !important;
  margin-right: 0px !important;
}
.ant-table-row-expand-icon {
  float: right !important;
}

.indent-level-1 {
  padding-left: 50px !important;
}

.table-row-light {
  background-color: #ffffff;
}