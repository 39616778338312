/* Below css for table resizable header */
.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

.dragHandler:hover {
  cursor: move;
  background-color: #ccc;
}

.table-header-drag-line {
  border-color: grey !important;
}

@keyframes fadeIn {
  0% {
    transform: translateY(-25%);
  }
  50% {
    transform: translateY(4%);
  }
  65% {
    transform: translateY(-2%);
  }
  80% {
    transform: translateY(2%);
  }
  95% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(0%);
  }
}
/* Below css is for right click table context menu */
.popup {
  /* animation-name: fadeIn;
  animation-duration: 0.4s; */
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  left: 0px;
  list-style-type: none;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 0px;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.popup li {
  clear: both;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  font-size: 18px;
  font-weight: normal;
  line-height: 30px;
  margin: 0;
  padding: 5px 12px;
  transition: all 0.3s;
  white-space: nowrap;
  -webkit-transition: all 0.3s;
}

.popup li:hover {
  background-color: #e6f7ff;
}

.popup li > i {
  margin-right: 8px;
}
/* Below css for draggable row */
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 2px 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}
