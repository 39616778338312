.ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-tooltip-inner > a {
  color: rgba(255, 255, 255, 0.65);
}

.ant-tooltip-inner > a:hover {
  color: white;
}
