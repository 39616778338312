/* Below css for table resizable header */
/* .tr .bg-yellow {
  background-color: '#ffffb8'!important;
} */
.ant-table-thead > tr > th.bg-yellow {
  background: #ffffb8 !important;
}
.ant-table-thead > tr > th.width-60 {
  width: 60% !important;
}
.ant-table-thead > tr > th.width-40 {
  width: 40% !important;
}
.ant-table-thead > tr > th.color-head-1 {
  background: #ffa500 !important;
}
.ant-table-thead > tr > th.color-head-2 {
  background: #ffcb6c !important;
}
.ant-table-thead > tr > th.color-head-3 {
  background: #ffebc7 !important;
}
.ant-table-thead > tr > th.color-head-4 {
  background: #233880 !important;
  color: #fff;
}
.ant-table-thead > tr > th.color-head-5 {
  background: #5b70b7 !important;
  color: #fff;
}
.tr > th.color-head-5 > :hover {
  background: #ffcb6c !important;
  color: #fff;
}
.ant-table-thead > tr > th.color-head-6 {
  background: #a6cbf5 !important;
}
.createTrip .ant-table-thead > tr > .ant-table-selection-column {
  background: #bfbfbf !important;
  /* color: #fff !important; */
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.width50 {
  visibility: visible;
}
