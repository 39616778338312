/* Below css for table resizable header */
/* .tr .bg-yellow {
  background-color: '#ffffb8'!important;
} */
.ant-table-thead > tr > th.bg-yellow {
  background: #ffffb8 !important;
}
.ant-table-thead > tr > th.bg-grey {
  background: #bfbfbf !important;
  /* color: #fff !important; */
}
.createTrip .ant-table-thead > tr > .ant-table-selection-column {
  background: #bfbfbf !important;
  /* color: #fff !important; */
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.ant-table-thead > tr > th.bg-cluster {
  border-right: 1px solid #bfbfbf !important;
  border-top: unset !important;
}
.ant-table-thead > tr > th.bg-destination {
  /* border-left: 1px solid #bfbfbf !important; */
  border-top: unset !important;
}

.date-picker-text-color > .ant-picker-input > input {
  color: rgb(255, 0, 0) !important;
}
