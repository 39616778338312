/* Below css for table resizable header */
/* .tr .bg-yellow {
  background-color: '#ffffb8'!important;
} */
.ant-table-thead > tr > th.bg-blue {
  background: #e4eefa !important;
}
.ant-table-tfoot > tr > th.bg-grey {
  background: #d9d9d9 !important;
}
.ant-table-tfoot > tr > th.bg-grey2 {
  background: #bfbfbf !important;
}
.ant-table-thead > tr > th.bg_from_trip_no {
  background: #d8e2dc !important;
  border-bottom: dotted !important;
}
 .ant-table-thead > tr > th.bg_to_trip_no {
  background: #d8e2dc !important;
  border-top: transparent !important;
}
.ant-table-thead > tr > th.bg_receive_trip_no {
  background: #ffe5d9 !important;
  border-bottom: dotted !important;
}
 .ant-table-thead > tr > th.bg_arrive_trip_no {
  background: #ffe5d9 !important;
  border-top: transparent !important;
}

.ant-table-thead > tr > th.bg_out_trip_no {
  background: #f9ce7d !important;
  border-bottom: dotted !important;
}
.ant-table-thead > tr > th.bg_out_color_trip_no {
  background: #f9ce7d !important;
  border-top: transparent !important;
}
.ant-table-thead > tr > th.bg-white {
  background: #ffffff !important;
}
.ant-table-thead > tr > th.width-50 {
  width: 50% !important;
}
.ant-table-thead > tr > th.color-head-1 {
  background: #ffa500 !important;
}
.ant-table-thead > tr > th.color-head-2 {
  background: #ffcb6c !important;
}
.ant-table-thead > tr > th.color-head-3 {
  background: #ffebc7 !important;
}
.ant-table-thead > tr > th.color-head-4 {
  background: #233880 !important;
  color: #fff;
}



.row-dragging td {
  padding: 16px;
}

.width50 {
  visibility: visible;
}

.row-mt-from-to {
  margin-top: -4px;
  margin-bottom: -2px;
}
