/* Below css for table resizable header */
/* .ant-modal-body */

.ant-modal-confirm-btns > button {
height: 50px;
width: 90px;
}

.ant-modal-footer > button {
height: 50px;
width: 90px;
}