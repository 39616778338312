.ant-table-thead > tr > th.bg-trip_no {
  background: #a6cbf5 !important;
  border: solid #a6cbf5 !important;
  border-top: 1px solid #000 !important;
  border-left: 1px solid #000 !important;
}

.ant-table-thead > tr > th.bg-to-trip_no {
  border-top: unset !important;
  background: #ffe5d9 !important;
}
.ant-table-thead > tr > th.bg-unit-trip_no {
  background: #f4acb7 !important;
}

.ant-table-thead > tr > th.bg-blue {
  background: #e4eefa !important;
}
.ant-table-tfoot > tr > th.bg-grey {
  background: #d9d9d9 !important;
}
.ant-table-tfoot > tr > th.bg-grey2 {
  background: #bfbfbf !important;
}

.ant-table-thead > tr > th.bg-white {
  background: #ffffff !important;
}
.ant-table-thead > tr > th.width-50 {
  width: 50% !important;
}
.ant-table-thead > tr > th.color-head-1 {
  background: #ffa500 !important;
}
.ant-table-thead > tr > th.color-head-2 {
  background: #ffcb6c !important;
}
.ant-table-thead > tr > th.color-head-3 {
  background: #ffebc7 !important;
}
.ant-table-thead > tr > th.hide-border-top {
  border-top: transparent !important;
}
.ant-table-thead > tr > th.hide-border-bottom {
  border-bottom: transparent !important;
}
.ant-table-thead > tr > th.color-head-4 {
  background: #233880 !important;
  color: #fff;
}
.ant-table-thead > tr > th.color-head-3s {
  background: #9d8189 !important;
  border-top-color: transparent !important;
  color: #fff;
}
.ant-table-thead > tr > th.color-head-1s {
  background: #fff !important;
  border-top-color: #fff !important;
  border-left-color: #fff !important;
}

.ant-table-thead > tr > th.color-head-5 {
  background: #5b70b7 !important;
  color: #fff;
}
.tr > th.color-head-5 > :hover {
  background: #ffcb6c !important;
  color: #fff;
}
.ant-table-thead > tr > th.color-head-6 {
  background: #a6cbf5 !important;
}
.ant-table-thead > tr > th.color-head-7 {
  background: #9d818973 !important;
}
.ant-table-thead > tr > th.color-head-8 {
  background: #d8e2dc !important;
}
.ant-table-thead > tr > th.color-head-9 {
  background: #ffe5d9 !important;
}
.ant-table-thead > tr > th.color-head-10 {
  background: #ffcad4 !important;
}
.ant-table-thead > tr > th.color-head-11 {
  background: #f4acb7 !important;
}
.createTrip .ant-table-thead > tr > .ant-table-selection-column {
  background: #bfbfbf !important;
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.width50 {
  visibility: visible;
}

.ant-table-tbody > tr > td {
  height: 5px;
  padding: 4px;
}
