/* Below css for table resizable header */
/* .tr .bg-yellow {
  background-color: '#ffffb8'!important;
} */
.ant-table-thead > tr > th.bg-blue {
  background: #e4eefa !important;
}
.ant-table-tfoot > tr > th.bg-grey {
  background: #d9d9d9 !important;

}
.ant-table-tfoot > tr > th.bg-grey2 {
  background: #bfbfbf !important;
}

.ant-table-thead > tr > th.bg-white {
  background: #ffffff !important;
}
.ant-table-thead > tr > th.width-50 {
  width: 50% !important;
}
.ant-table-thead > tr > th.color-head-1 {
  background: #ffa500 !important;
}
.ant-table-thead > tr > th.color-head-2 {
  background: #ffcb6c !important;
}
.ant-table-thead > tr > th.color-head-3 {
  background: #ffebc7 !important;
}
.ant-table-thead > tr > th.color-head-4 {
  background: #233880 !important;
  color: #fff;
}
.ant-table-tbody > tr > th.color-body-selected { 
  border-bottom-color: #5b70b7;
}
.ant-table-thead > tr > th.color-head-5 {
  background: #5b70b7 !important;
  color: #fff;
}
.tr > th.color-head-5 > :hover {
  background: #ffcb6c !important;
  color: #fff;
}
.ant-table-thead > tr > th.color-head-6 {
  background: #a6cbf5 !important;
}

.ant-table-thead > tr > th.color-head-6-remain_d {
  background: #d8e2dc !important;

}


.ant-table-thead > tr > th.border-bottom-row {
  border-bottom: dashed !important;
  border-bottom-color: grey !important;
  /* background: #D8E2DC !important; */
}

.ant-table-thead > tr > th.border-top-row {
  border-top: unset !important;
  border-top-color: grey !important;
  /* background: #D8E2DC !important; */
}



.ant-table-thead > tr > th.bg-to-trip_no {
  border-top: unset!important;;
  /* border-bottom-color: grey !important; */
  background: #FFE5D9 !important;
}



.ant-table-thead > tr > th.color-head-6-remain_n {
  background: #ffe5d9 !important;
}
.ant-table-thead > tr > th.color-head-6-remain_t {
  background: #f4acb7 !important;
}

.ant-table-thead > tr > th.color-head-6-border {
  background: #a6cbf5 !important;
  /* border-bottom: dashed !important;
  border-bottom-color: grey !important; */
}

.ant-table-thead > tr > th.color-head-6-border_left {
  background: #a6cbf5 !important;
  /* border-left: dashed !important;
  border-left-color: grey !important; */
}

.createTrip .ant-table-thead > tr > .ant-table-selection-column {
  background: #bfbfbf !important;
  /* color: #fff !important; */
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.width50 {
  visibility: visible;
}

/* row data */
.ant-table-tbody > tr > td {
  height: 5px;
  padding: 4px;
}